<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>广告图配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="广告图" prop="logo"><el-upload class="avatar-uploader" :action="$api.logoUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.guanggt" :src="info.guanggt" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                info:{
                    guanggt:''
                },
                upload_headers:{},
                haib:''
            };
        },
        watch: {},
        computed: {},
        methods: {
            submitForm:function(e){
                this.$refs[e].validate(res=>{
                    if(res){
                        // Http 请求
                        this.$post(this.$api.guanggtConfig,this.info).then(postRes=>{
                            if(postRes.code == 200){
                                this.$message.success("编辑成功");
                                this.get_web_config();
                            }else{
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.guanggt = res.data;
                this.$forceUpdate();
            },
            get_web_config(){
                this.$get(this.$api.guanggtConfig).then(res=>{
                    console.log(666)
                    console.log(res.data)
                    console.log(666)
                    this.info.guanggt  = res.data;
                    this.info.web_status = this.info.web_status==1?true:false;
                    this.info.goods_verify = this.info.goods_verify==1?true:false;
                });
            },

        },
        created() {
            this.get_web_config();
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>

</style>